import {
  SET_STORE_SETTINGS,
  REFRESH_CLOSED_STORE_SETTINGS,
} from "../actions/action-types"
import isClosed from "../../helperFunc/isClosed"

export default function storeSettingsReducer(state, action) {
  let newState = { ...state }

  switch (action.type) {
    case SET_STORE_SETTINGS:
      newState = action.payload
      return newState
    case REFRESH_CLOSED_STORE_SETTINGS:
      if (Object.keys(newState).length > 0 && !newState.error) {
        let {
          isResOutOfHours,
          minutesToClose,
          surcharge,
          orderWindows,
          tableList,
        } = isClosed(newState, newState.timeDiff)
        newState.isResOutOfHours = isResOutOfHours
        newState.minutesToClose = minutesToClose
        newState.surcharge = surcharge
        newState.orderWindows = orderWindows
        newState.tableList = tableList
        return newState
      } else {
        return state
      }

    default:
      return state
  }
}
