import { SET_SNACKBAR } from "../actions/action-types"

export default function feedbackReducer(state, action) {
  const { status, message, open, verticalPos, horizontalPos } = action.payload

  switch (action.type) {
    case SET_SNACKBAR:
      if (open === false) return { ...state, open }
      return {
        open: true,
        message,
        status,
        verticalPos: verticalPos ? verticalPos : "top",
        horizontalPos: horizontalPos ? horizontalPos : "right",
      }
    default:
      return state
  }
}
