import React, { useReducer, createContext, useEffect } from "react"
import storeSettingsReducer from "../reducers/store-settings-reducer"
import axios from "axios"
import { setStoreSettings } from "../actions"
import CircularProgress from "@material-ui/core/CircularProgress"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined"
import isClosed from "../../helperFunc/isClosed"

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  outBox: {
    width: "100%",
    minHeight: "calc(100vh  - 66px)",
    position: "relative",
    overflowY: "scroll",
  },

  inBox: {
    width: "80%",
    textAlign: "center",
    position: "absolute",
    left: "50%",
    top: "50%",
    msTransform: "translate(-50%, -50%)",
    transform: "translate(-50%, -50%)",
  },

  icon: {
    fontSize: "4rem !important",
  },
  logo: {
    marginTop: "3rem",
    maxWidth: "13rem",
    width: "90%",
  },
  loadingOut: {
    width: "100%",
    height: "95vh",

    position: "relative",
  },

  loadingIn: {
    width: "90%",
    textAlign: "center",
    position: "absolute",
    left: "50%",
    top: "50%",
    msTransform: "translate(-50%, -50%)",
    transform: "translate(-50%, -50%)",
  },
}))

export const StoreSettingsContext = createContext()
const StoreSettingsProvider = StoreSettingsContext.Provider

export function StoreSettingsWrapper({ children }) {
  const [loading, setLoading] = React.useState(true)

  const defaultStoreSettings = {}
  const classes = useStyles()

  const [storeSettings, dispatchStoreSettings] = useReducer(
    storeSettingsReducer,
    defaultStoreSettings
  )

  const stdTitle = "Service Unavailable"
  const stdMsg =
    "Unfortunately our online services are currently unavailable, please order at the counter, we apologise for the inconvenience."

  useEffect(() => {
    axios
      .get(
        process.env.GATSBY_STRAPI_URL + "/api/store-setting/get-store-setting"
      )
      .then(response => {
        let timeDiff = Date.now() - response.data.dateNow
        let {
          isResOutOfHours,
          minutesToClose,
          surcharge,
          orderWindows,
          tableList,
        } = isClosed(response.data, timeDiff)
        let dataToSet = {
          ...response.data,
          isResOutOfHours: isResOutOfHours,
          minutesToClose: minutesToClose,
          surcharge: surcharge,
          timeDiff: timeDiff,
          orderWindows: orderWindows,
          tableList: tableList,
        }
        dispatchStoreSettings(setStoreSettings(dataToSet))
        setLoading(false)
      })
      .catch(error => {
        console.error(error)
        dispatchStoreSettings(setStoreSettings({ error: true }))
        setLoading(false)
      })
  }, [])

  let pathname =
    typeof window !== "undefined"
      ? window.location.pathname.toLowerCase().split("/")[1]
      : ""

  return loading ? (
    <Box classes={{ root: classes.outBox }}>
      <Box classes={{ root: classes.inBox }}>
        <CircularProgress color="primary" />
      </Box>
    </Box>
  ) : (
    <StoreSettingsProvider value={{ storeSettings, dispatchStoreSettings }}>
      {Object.keys(storeSettings).length < 1 ? (
        <Box classes={{ root: classes.loadingOut }}>
          <Box classes={{ root: classes.loadingIn }}>
            <CircularProgress color="secondary" />
          </Box>
        </Box>
      ) : storeSettings.error ? (
        <Box classes={{ root: classes.loadingOut }}>
          <Box classes={{ root: classes.loadingIn }}>
            <ErrorOutlineOutlinedIcon classes={{ root: classes.icon }} />
            <Typography variant="h4" align={"center"} paragraph>
              {stdTitle}
            </Typography>
            <Typography variant="body1" align={"center"} paragraph>
              {stdMsg}
            </Typography>
          </Box>
        </Box>
      ) : (storeSettings.isRestaurantClosed ||
          (storeSettings.isTakeAwayClosed && storeSettings.isDineInClosed) ||
          (storeSettings.isTakeAwayClosed && storeSettings.isResOutOfHours)) &&
        !storeSettings.openPages.includes(pathname) ? (
        <Box classes={{ root: classes.loadingOut }}>
          <Box classes={{ root: classes.loadingIn }}>
            <Typography variant="h4" align={"center"} paragraph>
              {stdTitle}
            </Typography>
            <Typography variant="body1" align={"center"} paragraph>
              {storeSettings.customErrorMessage.replace(/\s/g, "").length > 0
                ? storeSettings.customErrorMessage
                : stdMsg}
            </Typography>
            <Box
              classes={{ root: classes.logo }}
              component="img"
              alt="logo"
              src={`${storeSettings.logo.url}`}
            />
          </Box>
        </Box>
      ) : (
        children
      )}
    </StoreSettingsProvider>
  )
}
