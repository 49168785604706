import {
  SET_STORE_SETTINGS,
  REFRESH_CLOSED_STORE_SETTINGS,
} from "./action-types"

export const setStoreSettings = data => ({
  type: SET_STORE_SETTINGS,
  payload: data,
})

export const refreshClosedStoreSettings = () => ({
  type: REFRESH_CLOSED_STORE_SETTINGS,
})
