import {
  SET_ORDER_OPTIONS,
  SET_ORDER_TOTAL,
  CLEAR_ORDER_OPTIONS,
  SET_ORDER_NOTES,
  UPDATE_ORDER_OPTIONS,
} from "./action-types"

export const setOrderOptions = orderOptions => ({
  type: SET_ORDER_OPTIONS,
  payload: orderOptions,
})

export const setOrderTotal = (
  totalOrderPrice,
  serviceFee,
  surchargeFee,
  tax,
  lastUpdated
) => ({
  type: SET_ORDER_TOTAL,
  payload: {
    totalOrderPrice,
    serviceFee,
    surchargeFee,
    tax,
    lastUpdated,
  },
})

export const setOrderNotes = (orderNotes, lastUpdated) => ({
  type: SET_ORDER_NOTES,
  payload: {
    orderNotes,
    lastUpdated,
  },
})

export const updateOrderOptions = (
  orderType,
  orderTableNumber,
  orderPickUpDate,
  orderPickUpTime,
  surchargeRate,
  lastUpdated
) => ({
  type: UPDATE_ORDER_OPTIONS,
  payload: {
    orderType,
    orderTableNumber,
    orderPickUpDate,
    orderPickUpTime,
    surchargeRate,
    lastUpdated,
  },
})

export const clearOrderOptions = () => ({
  type: CLEAR_ORDER_OPTIONS,
})
