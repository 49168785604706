import {
  ADD_TO_CART,
  ADD_SAVED_TO_CART,
  SET_STORED_CART,
  UPDATE_IN_CART,
  INC_IN_CART,
  REMOVE_FROM_CART,
  CLEAR_CART,
  CLEAR_UNAVAILABLE,
} from "../actions/action-types"

export default function cartReducer(state, action) {
  let newCart
  let existingIndex

  const storedCart = JSON.parse(localStorage.getItem("cart"))
  if (storedCart) {
    newCart = [...storedCart]
  } else {
    newCart = [...state]
  }

  if (action.payload) {
    existingIndex = state.findIndex(
      item => item.itemId === action.payload.itemId
    )
  }

  const saveData = cart => {
    localStorage.setItem("cart", JSON.stringify(cart))
  }

  switch (action.type) {
    case ADD_TO_CART:
      if (existingIndex !== -1) {
        let newQty = newCart[existingIndex].qty + action.payload.qty
        newCart[existingIndex] = { ...newCart[existingIndex], qty: newQty }
      } else {
        newCart.push(action.payload)
      }
      saveData(newCart)
      return newCart

    case ADD_SAVED_TO_CART:
      if (existingIndex !== -1) {
        newCart[existingIndex] = {
          ...newCart[existingIndex],
          qty: action.payload.qty,
          orderSelectedItems: action.payload.orderSelectedItems,
          totalItemPrice: action.payload.totalItemPrice,
        }
      } else {
        newCart.push(action.payload)
      }
      saveData(newCart)
      return newCart

    case SET_STORED_CART:
      return newCart

    case UPDATE_IN_CART:
      newCart[existingIndex] = {
        ...newCart[existingIndex],
        isAvailable: action.payload.isAvailable,
      }
      saveData(newCart)
      return newCart

    case INC_IN_CART:
      let newIncQty = newCart[existingIndex].qty + action.payload.qty
      newCart[existingIndex] = { ...newCart[existingIndex], qty: newIncQty }
      saveData(newCart)
      return newCart

    case REMOVE_FROM_CART:
      let newQty = newCart[existingIndex].qty - action.payload.qty
      if (newQty <= 0) {
        newCart = newCart.filter(item => item.itemId !== action.payload.itemId)
      } else {
        newCart[existingIndex] = { ...newCart[existingIndex], qty: newQty }
      }
      saveData(newCart)
      return newCart
    case CLEAR_CART:
      localStorage.removeItem("cart")
      return []

    case CLEAR_UNAVAILABLE:
      newCart = newCart.filter(item => item.isAvailable)
      saveData(newCart)
      return newCart
    default:
      return state
  }
}
