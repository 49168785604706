import {
  ADD_TO_CART,
  ADD_SAVED_TO_CART,
  SET_STORED_CART,
  UPDATE_IN_CART,
  INC_IN_CART,
  REMOVE_FROM_CART,
  CLEAR_CART,
  CLEAR_UNAVAILABLE,
} from "./action-types"

export const addToCart = (
  orderSelectedItems,
  qty,
  totalItemPrice,
  itemId,
  product,
  name,
  strapiId,
  section,
  isAvailable
) => ({
  type: ADD_TO_CART,
  payload: {
    orderSelectedItems,
    qty,
    totalItemPrice,
    itemId,
    product,
    name,
    strapiId,
    section,
    isAvailable,
  },
})

export const addSavedToCart = (
  orderSelectedItems,
  qty,
  totalItemPrice,
  itemId,
  product,
  name,
  strapiId,
  section,
  isAvailable
) => ({
  type: ADD_SAVED_TO_CART,
  payload: {
    orderSelectedItems,
    qty,
    totalItemPrice,
    itemId,
    product,
    name,
    strapiId,
    section,
    isAvailable,
  },
})

export const setStoredCart = () => ({
  type: SET_STORED_CART,
})

export const updateInCart = (isAvailable, itemId) => ({
  type: UPDATE_IN_CART,
  payload: { isAvailable, itemId },
})

export const incInCart = (qty, itemId) => ({
  type: INC_IN_CART,
  payload: { qty, itemId },
})

export const removeFromCart = (qty, itemId) => ({
  type: REMOVE_FROM_CART,
  payload: { qty, itemId },
})

export const clearCart = () => ({
  type: CLEAR_CART,
})

export const clearUnAvailable = () => ({
  type: CLEAR_UNAVAILABLE,
})
