import { SET_KITCHEN_SETTINGS } from "../actions/action-types"

export default function kitchenSettingsReducer(state, action) {
  let newState = { ...state }

  switch (action.type) {
    case SET_KITCHEN_SETTINGS:
      newState = action.payload
      return newState
    default:
      return state
  }
}
