exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-js": () => import("./../../../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-dine-in-js": () => import("./../../../src/pages/dine-in.js" /* webpackChunkName: "component---src-pages-dine-in-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-log-out-js": () => import("./../../../src/pages/log-out.js" /* webpackChunkName: "component---src-pages-log-out-js" */),
  "component---src-pages-menu-js": () => import("./../../../src/pages/menu.js" /* webpackChunkName: "component---src-pages-menu-js" */),
  "component---src-pages-orders-admin-index-js": () => import("./../../../src/pages/orders/admin/index.js" /* webpackChunkName: "component---src-pages-orders-admin-index-js" */),
  "component---src-pages-orders-admin-kitchen-settings-js": () => import("./../../../src/pages/orders/admin/kitchen-settings.js" /* webpackChunkName: "component---src-pages-orders-admin-kitchen-settings-js" */),
  "component---src-pages-orders-admin-log-out-js": () => import("./../../../src/pages/orders/admin/log-out.js" /* webpackChunkName: "component---src-pages-orders-admin-log-out-js" */),
  "component---src-pages-orders-admin-stock-js": () => import("./../../../src/pages/orders/admin/stock.js" /* webpackChunkName: "component---src-pages-orders-admin-stock-js" */),
  "component---src-pages-orders-admin-store-settings-js": () => import("./../../../src/pages/orders/admin/store-settings.js" /* webpackChunkName: "component---src-pages-orders-admin-store-settings-js" */),
  "component---src-pages-orders-main-js": () => import("./../../../src/pages/orders/main.js" /* webpackChunkName: "component---src-pages-orders-main-js" */),
  "component---src-pages-orders-section-js": () => import("./../../../src/pages/orders/section.js" /* webpackChunkName: "component---src-pages-orders-section-js" */),
  "component---src-pages-orders-tv-js": () => import("./../../../src/pages/orders/tv.js" /* webpackChunkName: "component---src-pages-orders-tv-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-templates-product-detail-js": () => import("./../../../src/templates/ProductDetail.js" /* webpackChunkName: "component---src-templates-product-detail-js" */)
}

