export const SET_USER = "SET_USER"

export const SET_SNACKBAR = "SET_SNACKBAR"

export const ADD_TO_CART = "ADD_TO_CART"
export const ADD_SAVED_TO_CART = "ADD_SAVED_TO_CART"
export const SET_STORED_CART = "SET_STORED_CART"
export const UPDATE_IN_CART = "UPDATE_IN_CART"
export const INC_IN_CART = "INC_IN_CART"
export const REMOVE_FROM_CART = "REMOVE_FROM_CART"
export const CLEAR_CART = "CLEAR_CART"
export const CLEAR_UNAVAILABLE = "CLEAR_UNAVAILABLE"

export const SET_ORDER_OPTIONS = "SET_ORDER_OPTIONS"
export const SET_ORDER_TOTAL = "SET_ORDER_TOTAL"
export const UPDATE_ORDER_OPTIONS = "UPDATE_ORDER_OPTIONS"
export const SET_ORDER_NOTES = "SET_ORDER_NOTES"
export const CLEAR_ORDER_OPTIONS = "CLEAR_ORDER_OPTIONS"

export const SET_STORE_SETTINGS = "SET_STORE_SETTINGS"
export const REFRESH_CLOSED_STORE_SETTINGS = "REFRESH_CLOSED_STORE_SETTINGS"

export const SET_KITCHEN_SETTINGS = "SET_KITCHEN_SETTINGS"
