import React, { useReducer, createContext, useEffect, useMemo } from "react"
import { IsEqual } from "lodash"
import orderOptionsReducer from "../reducers/order-options-reducer"
import {
  clearCart,
  setOrderTotal,
  setOrderOptions,
  setStoredCart,
  setUser,
} from "../actions"

import { CartContext, StoreSettingsContext, UserContext } from "../../contexts"
export const OrderOptionsContext = createContext()
const OrderOptionsProvider = OrderOptionsContext.Provider

export function OrderOptionsWrapper({ children }) {
  const { cart, dispatchCart } = React.useContext(CartContext)
  const { user, dispatchUser } = React.useContext(UserContext)
  const { storeSettings } = React.useContext(StoreSettingsContext)

  const storedOrderOptions =
    typeof window !== "undefined"
      ? JSON.parse(localStorage.getItem("orderOptions"))
      : null
  const hasFetchedData = React.useRef(
    storedOrderOptions ? storedOrderOptions.lastUpdated : null
  )

  const defaultOrderOptions = useMemo(() => {
    return {
      totalOrderPrice: 0,
      serviceFee: 0,
      surchargeFee: {
        phSurchargeFee: 0,
        sunSurchargeFee: 0,
        satSurchargeFee: 0,
      },
      surchargeRate: {
        phSurchargeRate: 0,
        sunSurchargeRate: 0,
        satSurchargeRate: 0,
      },
      tax: 0,
      orderType: storeSettings.isTakeAwayClosed ? null : "takeAway",
      orderTableNumber: null,
      orderPickUpDate: null,
      orderPickUpTime: null,
      orderNotes: "",
      lastUpdated: 0,
    }
  }, [storeSettings.isTakeAwayClosed])

  const serviceFeePer = useMemo(
    () => storeSettings.serviceFee,
    [storeSettings.serviceFee]
  )

  const [orderOptions, dispatchOrderOptions] = useReducer(
    orderOptionsReducer,
    storedOrderOptions || defaultOrderOptions
  )

  const surchargeRatePer = useMemo(
    () =>
      orderOptions.surchargeRate
        ? orderOptions.surchargeRate
        : {
            phSurchargeRate: 0,
            sunSurchargeRate: 0,
            satSurchargeRate: 0,
          },
    [orderOptions.surchargeRate]
  )

  useEffect(() => {
    let newTotal = 0

    cart.map((item, index) => {
      newTotal = parseFloat(
        (newTotal + item.qty * item.totalItemPrice).toFixed(2)
      )
      return newTotal
    })

    let newServiceFee = parseFloat(
      (Math.round(newTotal * (serviceFeePer / 100) * 100) / 100).toFixed(2)
    )

    let newSurchargeFee = {
      phSurchargeFee: 0,
      sunSurchargeFee: 0,
      satSurchargeFee: 0,
    }

    if (surchargeRatePer) {
      if (surchargeRatePer.phSurchargeRate) {
        newSurchargeFee.phSurchargeFee = parseFloat(
          (
            Math.round(
              newTotal * (surchargeRatePer.phSurchargeRate / 100) * 100
            ) / 100
          ).toFixed(2)
        )
      }
      if (surchargeRatePer.sunSurchargeRate) {
        newSurchargeFee.sunSurchargeFee = parseFloat(
          (
            Math.round(
              newTotal * (surchargeRatePer.sunSurchargeRate / 100) * 100
            ) / 100
          ).toFixed(2)
        )
      }
      if (surchargeRatePer.satSurchargeRate) {
        newSurchargeFee.satSurchargeFee = parseFloat(
          (
            Math.round(
              newTotal * (surchargeRatePer.satSurchargeRate / 100) * 100
            ) / 100
          ).toFixed(2)
        )
      }
    }
    let tax = parseFloat(
      (
        Math.round(
          (newTotal +
            newServiceFee +
            newSurchargeFee.phSurchargeFee +
            newSurchargeFee.sunSurchargeFee +
            newSurchargeFee.satSurchargeFee -
            (newTotal +
              newServiceFee +
              newSurchargeFee.phSurchargeFee +
              newSurchargeFee.sunSurchargeFee +
              newSurchargeFee.satSurchargeFee) /
              1.1) *
            100
        ) / 100
      ).toFixed(2)
    )
    dispatchOrderOptions(
      setOrderTotal(
        newTotal,
        newServiceFee,
        newSurchargeFee,
        tax,
        hasFetchedData.current ? hasFetchedData.current : Date.now()
      )
    )
    hasFetchedData.current = null
  }, [cart, serviceFeePer, surchargeRatePer])

  const timeBeforeReset = 60000 * 120

  useEffect(() => {
    if (
      JSON.parse(localStorage.getItem("user")) &&
      user &&
      !IsEqual(JSON.parse(localStorage.getItem("user")), user)
    ) {
      dispatchUser(setUser(JSON.parse(localStorage.getItem("user"))))
    }

    if (storedOrderOptions) {
      if (
        storedOrderOptions.lastUpdated !== 0 &&
        Date.now() - storedOrderOptions.lastUpdated > timeBeforeReset
      ) {
        dispatchCart(clearCart())
        localStorage.removeItem("orderOptions")
        localStorage.removeItem("intentID")
        dispatchOrderOptions(setOrderOptions(defaultOrderOptions))
      } else {
        if (
          JSON.parse(localStorage.getItem("cart")) &&
          cart &&
          !IsEqual(JSON.parse(localStorage.getItem("cart")), cart)
        ) {
          dispatchCart(setStoredCart())
        }
        if (
          JSON.parse(localStorage.getItem("orderOptions")) &&
          orderOptions &&
          !IsEqual(
            JSON.parse(localStorage.getItem("orderOptions")),
            orderOptions
          )
        ) {
          dispatchOrderOptions(
            setOrderOptions(JSON.parse(localStorage.getItem("orderOptions")))
          )
        }
      }
    }
  })

  return (
    <OrderOptionsProvider value={{ orderOptions, dispatchOrderOptions }}>
      {children}
    </OrderOptionsProvider>
  )
}
