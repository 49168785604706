import { createTheme } from "@material-ui/core/styles"

const theme = createTheme({
  palette: {
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#ff0000",
    },
    info: {
      main: "#ffffff",
    },
    action: {
      main: "#777777",
    },

    common: {
      received: "#606060",
      preparing: "#3e4bc5",
      ready: "#00939f",
      done: "#228636",

      grey: "#777777",
      darkGrey: "#555555",
      almostWhite: "#f3f3f3",
      offWhite: "#e6e6e6",
      seventyWhite: "#b3b3b3",
      white: "#fff",
      alertIcon: "#ef5350",
      aletBg: "#fdeded",
    },
  },
  typography: {
    h1: {
      fontSize: "4.5rem",
      fontFamily: "Roboto",
      fontWeight: 900,
    },
    h3: {
      fontSize: "3rem",
      lineHeight: "3rem",
      fontFamily: "Roboto",
      fontWeight: 700,
    },
    h4: {
      lineHeight: "2.5rem",
      fontSize: "2.5rem",
      fontFamily: "Roboto",
      fontWeight: 300,
    },
    h5: {
      fontSize: "1.7rem",
      fontFamily: "Roboto",
      fontWeight: 300,
    },

    h6: {
      lineHeight: "1.6rem",
      fontSize: "1.4rem",
      fontFamily: "Roboto",
      fontWeight: 700,
    },

    body1: {
      fontSize: "1rem",
      fontFamily: "Roboto",
      fontWeight: 100,
      lineHeight: "1.1rem",
    },
    body2: {
      fontSize: "1.5rem",
      fontFamily: "Roboto",
      fontWeight: 400,
    },
  },
  overrides: {},
})

export default theme
