import React, { useReducer, createContext } from "react"
import kitchenSettingsReducer from "../reducers/kitchen-settings-reducer"

export const KitchenSettingsContext = createContext()
const KitchenSettingsProvider = KitchenSettingsContext.Provider

export function KitchenSettingsWrapper({ children }) {
  const [kitchenSettings, dispatchKitchenSettings] = useReducer(
    kitchenSettingsReducer,
    {}
  )

  return (
    <KitchenSettingsProvider
      value={{ kitchenSettings, dispatchKitchenSettings }}
    >
      {children}
    </KitchenSettingsProvider>
  )
}
