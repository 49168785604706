import {
  SET_ORDER_OPTIONS,
  SET_ORDER_TOTAL,
  CLEAR_ORDER_OPTIONS,
  SET_ORDER_NOTES,
  UPDATE_ORDER_OPTIONS,
} from "../actions/action-types"

export default function orderOptionsReducer(state, action) {
  let newOrderOptions = { ...state }
  let defaultOrderOptions = {
    totalOrderPrice: 0,
    serviceFee: 0,
    surchargeFee: { phSurchargeFee: 0, sunSurchargeFee: 0, satSurchargeFee: 0 },
    surchargeRate: {
      phSurchargeRate: 0,
      sunSurchargeRate: 0,
      satSurchargeRate: 0,
    },
    tax: 0,
    orderType: null,
    orderTableNumber: null,
    orderPickUpDate: null,
    orderPickUpTime: null,
    orderNotes: "",
    lastUpdated: 0,
  }

  const saveData = orderOptions => {
    localStorage.setItem("orderOptions", JSON.stringify(orderOptions))
  }

  switch (action.type) {
    case SET_ORDER_OPTIONS:
      newOrderOptions = action.payload
      saveData(newOrderOptions)
      return newOrderOptions

    case SET_ORDER_TOTAL:
      newOrderOptions.totalOrderPrice = action.payload.totalOrderPrice
      newOrderOptions.serviceFee = action.payload.serviceFee
      newOrderOptions.surchargeFee = action.payload.surchargeFee
      newOrderOptions.tax = action.payload.tax
      newOrderOptions.lastUpdated = action.payload.lastUpdated
      saveData(newOrderOptions)
      return newOrderOptions

    case UPDATE_ORDER_OPTIONS:
      newOrderOptions.orderType = action.payload.orderType
      newOrderOptions.orderTableNumber = action.payload.orderTableNumber
      newOrderOptions.orderPickUpDate = action.payload.orderPickUpDate
      newOrderOptions.orderPickUpTime = action.payload.orderPickUpTime
      newOrderOptions.surchargeRate = action.payload.surchargeRate
      newOrderOptions.lastUpdated = action.payload.lastUpdated
      saveData(newOrderOptions)
      return newOrderOptions

    case SET_ORDER_NOTES:
      newOrderOptions.orderNotes = action.payload.orderNotes
      newOrderOptions.lastUpdated = action.payload.lastUpdated
      saveData(newOrderOptions)
      return newOrderOptions

    case CLEAR_ORDER_OPTIONS:
      localStorage.removeItem("orderOptions")
      return defaultOrderOptions
    default:
      return state
  }
}
