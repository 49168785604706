const isClosed = (dataIn, timeDiffIn) => {
  let timeDiff = timeDiffIn ? timeDiffIn : Date.now() - dataIn.dateNow
  let timeNow = new Date(
    Date.now() + ((0 + dataIn.timeZone) * 3600000 - timeDiff)
  )
  let days = [
    { O: "sunO", C: "sunC", isClosed: "isSunC", name: "Sunday" },
    { O: "monO", C: "monC", isClosed: "isMonC", name: "Monday" },
    { O: "tueO", C: "tueC", isClosed: "isTueC", name: "Tuesday" },
    { O: "wedO", C: "wedC", isClosed: "isWedC", name: "Wednesday" },
    { O: "thuO", C: "thuC", isClosed: "isThuC", name: "Thursday" },
    { O: "friO", C: "friC", isClosed: "isFriC", name: "Friday" },
    { O: "satO", C: "satC", isClosed: "isSatC", name: "Saturday" },
  ]
  let timeNowDay = timeNow.getUTCDay()
  let timeNowHours = timeNow.getUTCHours()
  let timeNowMinutes = timeNow.getUTCMinutes()
  let testTime = timeNowHours * 60 + timeNowMinutes

  const getDateObj = timeIn => {
    let fullYear = timeIn.getUTCFullYear()
    let monthInd = timeIn.getUTCMonth()
    let dayOfMonth = timeIn.getUTCDate()
    let dayOfWeek = timeIn.getUTCDay()
    let label = `${dayOfMonth < 10 ? `0${dayOfMonth}` : `${dayOfMonth}`}/${
      monthInd + 1 < 10 ? `0${monthInd + 1}` : `${monthInd + 1}`
    }/${fullYear}`
    return {
      javaDateUTC: timeIn,
      fullYear,
      monthInd,
      dayOfMonth,
      dayOfWeek,
      label,
    }
  }

  const checkDayIndex = dayIndexIn => {
    if (dayIndexIn > 6) {
      let dayIndexOut = dayIndexIn
      for (dayIndexOut; dayIndexOut > 6; dayIndexOut = dayIndexOut - 7) {}
      return dayIndexOut
    } else {
      return dayIndexIn
    }
  }

  const getDayName = dayIndexIn => {
    return days[checkDayIndex(dayIndexIn)].name
  }

  const getTime = (dayIndexIn, openOrClose) => {
    return dataIn[`${days[checkDayIndex(dayIndexIn)][openOrClose]}`]
  }

  const isOpen = (testTimeIn, dayIndex, timeNowIn) => {
    return (
      testTimeIn >= getTime(checkDayIndex(dayIndex), "O") &&
      testTimeIn <= getTime(checkDayIndex(dayIndex), "C") &&
      !getTime(checkDayIndex(dayIndex), "isClosed") &&
      !dataIn.closedDates.includes(getDateObj(timeNowIn).label)
    )
  }

  const isDateOpen = (dayIndex, timeIn) => {
    return (
      !getTime(checkDayIndex(dayIndex), "isClosed") &&
      !dataIn.closedDates.includes(getDateObj(timeIn).label)
    )
  }
  const getSlots = dayOfWeekIn => {
    let steps = []
    let openTime = getTime(dayOfWeekIn, "O")

    let stepsCount = Math.floor(
      (getTime(dayOfWeekIn, "C") - openTime) / dataIn.orderMinutesWindow
    )
    for (let i = 0; i <= stepsCount; i++) {
      steps.push(i * dataIn.orderMinutesWindow + openTime)
    }

    return steps
  }

  const getSurcharge = timeIn => {
    let surcharge = {
      phSurchargeRate: 0,
      sunSurchargeRate: 0,
      satSurchargeRate: 0,
    }
    if (
      dataIn.phSurchargeDates.includes(getDateObj(timeIn).label) &&
      dataIn.isPhSurcharge
    ) {
      surcharge.phSurchargeRate = dataIn.phSurchargeRate
    } else if (getDateObj(timeIn).dayOfWeek === 0 && dataIn.isSunSurcharge) {
      surcharge.sunSurchargeRate = dataIn.sunSurchargeRate
    } else if (getDateObj(timeIn).dayOfWeek === 6 && dataIn.isSatSurcharge) {
      surcharge.satSurchargeRate = dataIn.satSurchargeRate
    }
    return surcharge
  }

  const getOrderWindows = () => {
    let orderWindowsOut = []
    // change 08-12-22 to fix starting time other then 0

    let startTimeMin = Math.floor(
      getTime(timeNowDay, "O") -
        Math.floor(
          Math.floor(getTime(timeNowDay, "O") / dataIn.orderMinutesWindow) *
            dataIn.orderMinutesWindow
        )
    )
    let startingMinutesWindow = startTimeMin
    let stepsCount = Math.floor(60 / dataIn.orderMinutesWindow) * 24

    for (let i = 0; i <= stepsCount; i++) {
      if (
        i * dataIn.orderMinutesWindow + startTimeMin >=
        timeNowMinutes +
          timeNowHours * 60 +
          dataIn.minOrderMinutesWindow +
          dataIn.orderMinutesBuffer
      ) {
        startingMinutesWindow = i * dataIn.orderMinutesWindow + startTimeMin
        break
      }
    }

    // change 08-12-22 to fix starting time other then 0

    let dayOneSteps = []
    if (isOpen(startingMinutesWindow, timeNowDay, timeNow)) {
      let numOfsteps = Math.floor(
        (getTime(timeNowDay, "C") - startingMinutesWindow) /
          dataIn.orderMinutesWindow
      )
      for (let i = 0; i <= numOfsteps; i++) {
        dayOneSteps.push(i * dataIn.orderMinutesWindow + startingMinutesWindow)
      }
    } else if (
      getTime(timeNowDay, "O") > startingMinutesWindow &&
      isDateOpen(timeNowDay, timeNow)
    ) {
      dayOneSteps = []
      dayOneSteps = getSlots(timeNowDay)
    }

    for (let i = 0; i < dataIn.orderDaysAhead; i++) {
      if (i === 0 && dayOneSteps.length > 0) {
        let dayNameCalcOne = getDayName(timeNowDay)
        orderWindowsOut.push({
          date: {
            ...getDateObj(timeNow),
            dayLabel: "Today",
            dayName: dayNameCalcOne,
          },
          dayLabel: "Today",
          dayName: dayNameCalcOne,
          slots: dayOneSteps,
          surcharge: getSurcharge(timeNow),
        })
      } else if (i > 0) {
        let newDate = new Date(Date.parse(timeNow) + i * 24 * 3600000)
        let newDateObj = getDateObj(newDate)
        if (isDateOpen(newDateObj.dayOfWeek, newDate)) {
          let slotsOut = getSlots(newDateObj.dayOfWeek)
          if (slotsOut.length > 0) {
            let dayNameCalcTwo = getDayName(newDateObj.dayOfWeek)
            orderWindowsOut.push({
              date: {
                ...newDateObj,
                dayLabel: i === 1 ? "Tomorrow" : null,
                dayName: dayNameCalcTwo,
              },
              dayLabel: i === 1 ? "Tomorrow" : null,
              dayName: dayNameCalcTwo,
              slots: slotsOut,
              surcharge: getSurcharge(newDate),
            })
          }
        }
      }
    }
    return orderWindowsOut
    //console.log(new Date(Date.parse(timeNow) + 24 * 3600000).toISOString())
  }

  const getTableList = () => {
    let tables = new Array(
      dataIn.tablesEndingNumber - dataIn.tablesStartingNumber + 1
    )
    for (let i = 0; i < tables.length; i++) {
      tables[i] = dataIn.tablesStartingNumber + i
    }
    return tables
  }

  const testTimeFunc = () => {
    if (isOpen(testTime, timeNowDay, timeNow)) {
      return {
        isResOutOfHours: false,
        minutesToClose: dataIn[`${days[timeNowDay].C}`] - testTime,
        surcharge: getSurcharge(timeNow),
        orderWindows: getOrderWindows(),
        tableList: getTableList(),
      }
    } else {
      return {
        isResOutOfHours: true,
        minutesToClose: 0,
        surcharge: getSurcharge(timeNow),
        orderWindows: getOrderWindows(),
        tableList: getTableList(),
      }
    }
  }

  return testTimeFunc()
}

export default isClosed
