import React, { useReducer, createContext } from "react"
import Snackbar from "@material-ui/core/Snackbar"
import Typography from "@material-ui/core/Typography"
import MuiAlert from "@material-ui/lab/Alert"

import feedbackReducer from "../reducers/feedback-reducer"
import { setSnackbar } from "../actions"

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  snackbar: {
    marginTop: "5rem",
  },
}))

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

export const FeedbackContext = createContext()
const FeedbackProvider = FeedbackContext.Provider

export function FeedbackWrapper({ children }) {
  const [feedback, dispatchFeedback] = useReducer(feedbackReducer, {
    open: false,
    status: "",
    message: "",
    verticalPos: "top",
    horizontalPos: "right",
  })

  const classes = useStyles()

  return (
    <FeedbackProvider value={{ feedback, dispatchFeedback }}>
      {children}
      <Snackbar
        classes={{ root: classes.snackbar }}
        open={feedback.open}
        anchorOrigin={{
          vertical: feedback.verticalPos,
          horizontal: feedback.horizontalPos,
        }}
        autoHideDuration={6000}
        onClose={() => dispatchFeedback(setSnackbar({ open: false }))}
      >
        <Alert
          onClose={() => dispatchFeedback(setSnackbar({ open: false }))}
          severity={feedback.status}
          sx={{ width: "100%" }}
        >
          <Typography variant={"body1"}>{feedback.message}</Typography>
        </Alert>
      </Snackbar>
    </FeedbackProvider>
  )
}
